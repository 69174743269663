// @flow
/* global window fetch */

import * as React from 'react';
import Head from 'next/head';
import config from 'config';
import { CookieUtils, } from '@haaretz/htz-user-utils';
import { useRouter, } from 'next/router';
import UserDispenser from '../User/UserDispenser';
import isQueryValueTrue from '../../utils/isQueryValueTrue';
import useWebViewChecker from '../../hooks/useWebViewChecker';

const GROUPS_REGEX = /(?:groups=)((C000\d:[01],?)+)/i;
const GDPR_URL = `${config.get('service.sso')}/updateGdpr`;
const flags = [ 'S', 'P', 'F', 'T', ]; // [ S-trictly,  P-erformance, F-unctional, T-argeting, ]

const groups: Object = flags.reduce(
  (obj, f) => ({ ...obj, [f]: '0', }), {}
); // Init all flags to '0'

function initGroups() {
  let cookieConsentValue = CookieUtils.getCookie('OptanonConsent', true);

  if (cookieConsentValue != null) {
    cookieConsentValue = decodeURIComponent(cookieConsentValue);
    const match = GROUPS_REGEX.exec(cookieConsentValue);
    const userData = match ? match[1] : null;
    userData && userData
      .split(',') // Split to : [ '1:1' , '2:0' , '3:1' , '4:1' ]
      .map(item => item.replace('C000', '').split(':')) // returns: [ [ '1', '1'] , [ '2', '0'] , [ '3', '1' ] , [ '4', '1' ] ]
      .forEach(item => {
        const key = flags[parseInt(item[0], 10) - 1];
        if (key) {
          groups[key] = item[1];
        }
      });
  }
}

export default function CookieConsent() {
  const router = useRouter();
  const isGooglebot = isQueryValueTrue(router.query.isBot);
  const isWebView = useWebViewChecker();

  return isGooglebot || isWebView ? null : (
    <UserDispenser render={({ user, }) => (
      <CookieConsentInner user={user} />)
    }
    />
  );
}

type PropsType = {
    user: { email: string, },
}

function CookieConsentInner({ user, }: PropsType) {
  React.useEffect(() => {
    // Log Consent Data to SSO
    if (user && user.email) {
      initGroups();
      const postData = Object.assign({}, groups);
      fetch(GDPR_URL,
        {
          headers: { 'Content-Type': 'application/json', },
          method: 'POST',
          body: JSON.stringify(postData),
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return config.has('cookieConsentBaseUrl')
    ? (
      <Head>
        <script src={config.get('cookieConsentBaseUrl')} id="cookie-consent-main" charset="UTF-8" data-domain-script={config.get('cookieConsentDomainScript')} />
        <script
          id="cookie-consent-sub"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
                function OptanonWrapper()
                { /* empty function */ }
                `,
          }}
        />
      </Head>
    )
    : null;
}
